<div class="auth-wrapper maintenance">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="text-center">
                    <img src="assets/images/maintenance/403.png" alt="" class="img-fluid" style="width: 50%;">
                    <h5 class="text-muted my-4">Acceso no permitido!. Por favor inicie sesión.</h5>
                    <button class="btn waves-effect waves-light btn-primary mb-4" (click)="irLogin()"><i
                            class="feather icon-refresh-ccw mr-2"></i>Login</button>
                </div>
            </div>
        </div>
    </div>
</div>
