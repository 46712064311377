import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from 'src/app/_service/publico/login.service';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
  usuario: string;
  constructor(private loginService: LoginService,
    private router: Router) { }

  ngOnInit() {
    this.usuario = this.loginService.usuario;
  }


  cerrarSesion() {
    this.loginService.logueado = false;
    this.loginService.usuario = null;
    this.router.navigate(['/login']);
  }
}
