<router-outlet></router-outlet>
<div class="auth-wrapper align-items-stretch aut-bg-img">
    <div class="flex-grow-1">
        <div class="h-100 d-md-flex align-items-center auth-side-img">
            <div class="col-sm-10 auth-content w-auto">
                <img src="assets/images/auth/auth-logo.png" alt="" class="img-fluid">
                <h1 class="text-white my-4">Aplicación de Contratos</h1>
                <h4 class="text-white font-weight-normal"></h4>
            </div>
        </div>
        <div class="auth-side-form">
            <div class=" auth-content">
                <form #loginForm="ngForm" class="editForm" novalidate>
                    <img src="assets/images/auth/auth-logo-dark.png" alt=""
                        class="img-fluid mb-4 d-block d-xl-none d-lg-none">
                    <h3 class="mb-4 f-w-400">Login</h3>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="feather icon-user"></i></span>
                        </div>
                        <input type="text" class="form-control" placeholder="User" style="text-transform: uppercase;"
                            [(ngModel)]="usuario" name="usuario"
                            [ngClass]="{'is-invalid': !user.valid && (user.dirty || user.touched || isSubmit)}"
                            #user="ngModel" required autocomplete="off">
                        <label *ngIf="!user.valid && (user.dirty || user.touched || isSubmit)"
                            id="validation-user-error"
                            class="error jquery-validation-error small form-text invalid-feedback">Ingrese el
                            usuario</label>
                    </div>
                    <div class="input-group mb-4">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="feather icon-lock"></i></span>
                        </div>
                        <input type="password" class="form-control" placeholder="Password" name="clave" [(ngModel)]="clave"
                            [ngClass]="{'is-invalid': !pass.valid && (pass.dirty || pass.touched || isSubmit)}"
                            #pass="ngModel" required>
                        <label *ngIf="!pass.valid && (pass.dirty || pass.touched || isSubmit)"
                            id="validation-pass-error"
                            class="error jquery-validation-error small form-text invalid-feedback">Ingrese la
                            contraseña</label>
                    </div>
                </form>
                <button class="btn btn-block btn-primary mb-0" [disabled]="spinnerVisible" (click)="login(loginForm)">Iniciar</button>


                <div class="row">
                    <div class="col-sm-12">
                        <br />
                        <div class="d-flex justify-content-center">
                            <div class="spinner-grow" role="status" *ngIf="spinnerVisible">
                                <span class="sr-only"></span>
                            </div>
                        </div>
                        <app-alert type="success" dismiss="true" *ngIf="mensaje">{{mensaje}}</app-alert>
                        <app-alert type="danger" dismiss="true" *ngIf="error">{{error}}</app-alert>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>