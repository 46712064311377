import { Component, OnInit } from '@angular/core';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not403',
  templateUrl: './not403.component.html',
  styleUrls: ['./not403.component.scss']
})
export class Not403Component implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  irLogin() {
this.router.navigate(['/login']);
  }
}
