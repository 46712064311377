import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../src/environments/environment';
import { LoginInput } from '../../_model/publico/login_input';
import { EstadoRespuesta } from "../../_model/publico/estado_respuesta";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private url_login = `${environment.HOST}/usuarios/login`;
  logueado: boolean = false;
  usuario: string;

  constructor(private http: HttpClient) { }

  login(content: LoginInput) {
    return this.http.post<EstadoRespuesta>(this.url_login, content);
  }
}
