<ul class="navbar-nav ml-auto">
  <li>
    <span>Hello, {{usuario}}</span>
  </li>
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">

      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <i class="icon feather icon-user"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
          <img src="assets/images/user/avatar.png" class="img-radius" alt="User-Profile-Image">
          <span>{{usuario}}</span>
          <a href="javascript:" class="dud-logout" title="Logout">
            <i class="feather icon-log-out" (click)="cerrarSesion()"></i>
          </a>
        </div>
        <ul class="pro-body">
          <li><a href="javascript:" class="dropdown-item"><i class="feather icon-user"></i> Profile</a></li>
        </ul>
      </div>
    </div>
  </li>
</ul>